/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { FC } from "react"

type FieldErrorProps = {
    errors: string[]
}

const errorStyle = css`
    color: #a00; 
    margin: -10px 0 5px;
    display: none;
`

export const FieldError: FC<FieldErrorProps> = ({errors}) => {
    const error = errors.join(", ")
    if (!error) return <></>
    return <div css={errorStyle} className="field-error">{ error }</div>
}